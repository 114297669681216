<template>
  <a-modal
    title="Выбор адреса"
    :visible="modal"
    :closable="true"
    @cancel="close"
    centered
  >
    <div id="yandex-map"></div>
    <template #footer>
      <a-button @click="definePosition" type="link">Определить мою геопозицию</a-button>
      <a-button @click="close">Закрыть</a-button>
      <a-button @click="save" type="primary">Выбрать</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import axios from 'axios'
import { watchEffect } from 'vue'

const props = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  coords: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['hide-map'])

let coordinates = [];
let address = '';
let address_description = '';

const close = () => {
  emit('hide-map', false);
  coordinates = [];
  address = '';
  address_description = '';
}
const save = () => {
  emit('hide-map', {
    'coordinates': coordinates,
    'address': address,
    'address_description': address_description,
  });
  coordinates = [];
  address = '';
  address_description = '';
}

let getCurrentPositionFunc

const initializeYandexMap = () => {
  let mapDiv = document.getElementById('yandex-map');
  if (mapDiv && mapDiv.hasChildNodes())
    return;

  window.ymaps.ready(() => {
    let map = new window.ymaps.Map("yandex-map", {
      center: [41.31115, 69.279737],
      zoom: 11,
      controls: ['fullscreenControl'],
      searchControlProvider: 'yandex#search',
    });
    let searchControl = new window.ymaps.control.SearchControl({
      options: {
        provider: 'yandex#search'
      }
    });
    map.controls.add(searchControl);

    getCurrentPositionFunc = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success => {
          map.geoObjects.each((context) => {
            map.geoObjects.remove(context);
          });
          let currentAddress = [success.coords.latitude.toString(), success.coords.longitude.toString()]
          let currentPlacemark = new window.ymaps.Placemark(currentAddress)
          map.geoObjects.add(currentPlacemark)
          coordinates = currentAddress
          getAddress([success.coords.latitude, success.coords.longitude])
        })
      }
    }

    if (props.coords) {
      map.geoObjects.each((context) => {
        map.geoObjects.remove(context);
      });
      let currentAddress = props.coords.split(',');
      let currentPlacemark = new window.ymaps.Placemark(currentAddress);
      map.geoObjects.add(currentPlacemark);
      coordinates = currentAddress;
    }
    // } else {
      // getCurrentPositionFunc()
    // }

    map.events.add('click', (e) => {
      map.geoObjects.each((context) => {
        map.geoObjects.remove(context);
      });
      let placemark = new window.ymaps.Placemark(e.get('coords'));
      map.geoObjects.add(placemark);
      coordinates = e.get('coords');
      getAddress(coordinates)
      // window.ymaps.geocode(coordinates).then((res) => {
      //   const firstGeoObject = res.geoObjects.get(0);
      //   address = firstGeoObject
      //     .getAddressLine()
      //     .replace(firstGeoObject.getCountry() + ", ", "");
      // })
    });
  });
}

const definePosition = () => {
  getCurrentPositionFunc()
}

const getAddress = (coordinates) => {
  const url = 'https://geocode-maps.yandex.ru/1.x/?apikey=e9fe7cab-eb17-4480-976c-a9bd1756a5c1&format=json&geocode=' + coordinates[1] + ',' + coordinates[0]
  axios.get(url)
    .then((res) => {
      const addressResponse = res.data.response.GeoObjectCollection.featureMember[0]
      address = addressResponse['GeoObject']['name']
      address_description = addressResponse['GeoObject']['description']
    })
}

watchEffect(() => {
  if (props.modal) {
    initializeYandexMap()
  }
})

</script>

<style>
#yandex-map {
  width: 100%;
  height: 400px;
  overflow: hidden;
}
</style>
